<template>
  <el-row type="flex" class="is-flex is-flex-wrap summary" :gutter="15">
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Information</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="information" :show-header="false">
            <el-table-column width="120">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Customer Type</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="users" :show-header="false">
            <el-table-column width="200">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Validity</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="validity" :show-header="false">
            <el-table-column>
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<template>
  <el-row type="flex" class="is-flex is-flex-wrap summary" :gutter="15">
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Information</p>
          <el-tag v-if="discount?.is_affiliate_code"> AFFILIATE CODE </el-tag>
        </div>
        <div class="discount-summary--content">
          <el-table :data="information" :show-header="false">
            <el-table-column width="120">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Customer Type</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="users" :show-header="false">
            <el-table-column width="200">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Validity</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="validity" :show-header="false">
            <el-table-column>
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "DiscountSummary",
  props: {
    discount: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    information() {
      return [
        {
          label: "Discount Category",
          value:
            this.discount && this.discount.discount_category === "order_amount"
              ? "Order Amount"
              : "Delivery Fee",
        },
        {
          label: "Discount Value",
          value:
            this.discount &&
            (this.discount.discount_type === "percentage"
              ? `${this.discount.value}%`
              : `${this.formatPrice(this.discount.value)}`),
        },
      ];
    },
    users() {
      let customer_info = [
        {
          label: "Customers Using discount",
          value: this.discount && this.discount.users_count,
        },
      ];

      if (this.discount && this.discount.one_off) {
        customer_info.push({
          label: "Customers Can Use discount",
          value: "Once",
        });
      } else if (this.discount) {
        customer_info.push({
          label: "Customer Type",
          value:
            this.discount.customer_type === "return_customers"
              ? "Return Customers"
              : this.discount.customer_type === "first_time_customers"
              ? "First Time Customers"
              : "All Users",
        });
      }
      return customer_info;
    },
    validity() {
      return [
        {
          label: "Date Created",
          value:
            this.discount && this.discount.start_date
              ? this.formatDate(this.discount.start_date, "do m, y")
              : "Never",
        },
        {
          label: "Expiry Date",
          value:
            this.discount && this.discount.expiry_date
              ? this.formatDate(this.discount.expiry_date, "do m, y")
              : "Never",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-summary {
  border: 1px solid var(--eden-grey-senary);
  border-radius: 8px;
  padding: 15px 12px 15px;

  &--title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 1020px) {
  .summary {
    gap: 10px !important;
  }
}
</style>
